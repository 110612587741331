import React, { useState, useEffect, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Card, Form, Item, Icon, Button, Divider } from "semantic-ui-react";
import {
  createJob,
  getAllClients,
  fetchAllPartnerAdmins,
  getAllZyvkaUsers,
} from "../api/ZyvkaMember";
import { UserState, selectUser } from "../features/userSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getJdFromURL, profilePicURL } from "../utils/functions";
import {
  selectAllPartnerAdminsDetails,
  ZyvkaMemberState,
} from "../features/ZyvkaMemberSlice";
import { getAllJobProfiles, fetchAllSkills } from "../api/auth";

import axios from "axios";
import RichTextEditor from "../Small-Components/RichTextEditor";
import { useParams } from "react-router-dom";
import Currency from "../Data/Currency";
import ErrorMessage from "../Error/ErrorMessage";
import QuestionsForJob from "./QuestionsForJob";


const CloneJob = () => {
  const [openLocation, setOpentLocation] = useState(false);
  const [openJD, setOpenJD] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const userState = useSelector(UserState);
  const [country, setCountry] = useState("");
  const [countryOption, setCountryOption] = useState([]);
  const [stateOption, setStateOption] = useState([]);
  const [cityOption, setCityOption] = useState([]);
  const [profile, setProfile] = useState(null);
  const [state, setState] = useState(null);
  const [selectedClientID, setSelectedCLientID] = useState();
  const zyvkaState = useSelector(ZyvkaMemberState);
  const [formData, setFormData] = useState(null);
  const clients = zyvkaState.AllClients;
  const { jobID } = useParams();
  const navigate = useNavigate();
  const [showQuestionModal, setShowQuestionModal] = useState(false);


  const addQuestionClick = () => {
    setShowQuestionModal(e => !e)
    console.log(showQuestionModal)
  }

  const onFinish = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("zyvkaUserID", user.id);
    formdata.append("job_title", formData?.job_title);
    formdata.append("job_description", formData?.job_description);
    formdata.append("skills_req", formData?.skills_req);
    formdata.append("file", formData?.file);
    formdata.append("min_experience_req", formData.min_experience_req);
    formdata.append("max_experience_req", formData.max_experience_req);
    formdata.append(
      "country",
      openLocation
        ? countryOption?.find((e) => e.iso2 === formData?.country).name
        : formData?.prev_country
    );
    formdata.append("number_of_positions", formData?.number_of_positions);
    formdata.append(
      "state",
      openLocation
        ? stateOption?.find((e) => e.iso2 === formData?.state).name
        : formData?.prev_state
    );
    formdata.append(
      "city",
      openLocation ? formData?.city : formData?.prev_city
    );
    formdata.append("prefered_industry", formData?.prefered_industry);
    formdata.append("certification", formData?.certification);
    formdata.append("min_CTC", formData?.mnCtc);
    formdata.append("max_CTC", formData?.mxCtc);
    formdata.append("notice_period", formData?.notice_period);
    formdata.append("hiring_process", JSON.stringify(formData?.hiring_process));
    formdata.append("company_name", formData?.company_name);
    formdata.append("number_of_rounds", formData?.number_of_rounds);
    formdata.append("assigned_partners", formData?.assigned_partners);
    //addtn
    formdata.append("questions", JSON.stringify(formData?.questions));
    formdata.append("questionType", JSON.stringify(formData?.questionType));

    formdata.append("zyvka_team_members", formData?.zyvka_team_members);
    formdata.append("openToAll", formData?.openToAll);
    formdata.append("clientId", selectedClientID);
    formdata.append("clientUserId", formData?.clientUserId);
    formdata.append("jobType", formData?.jobType);
    formdata.append("ESOP", formData?.esop);
    formdata.append("Drive", formData?.drive);
    formdata.append("internalTeamWorking", formData?.IsInternalTeamWorking);
    formdata.append("isVideoResumeRequired", formData?.isVideoResumeRequired);
    formdata.append("isZyvkaAiEnable", formData?.isZyvkaAiEnable);
    formdata.append("openToInternalTeamOnly", formData?.openToInternalTeamOnly);
    formdata.append("hotJob", formData?.hotJob);
    formdata.append("currencyType", formData?.currencyType);
    formdata.append("prevJD", !openJD);
    formdata.append("prev_jd_urn", formData?.jd_urn);
    formdata.append("isRemote", formData?.remoteJob);
    await createJob(formdata, dispatch, navigate, user?.id);
  };

  const clientSelectedHandler = (e) => {
    const val = clients.find((item) => item.name === e);
    setSelectedCLientID(val?.id);
  };
  const fetchJobProfiles = async () => {
    try {
      await getAllJobProfiles(dispatch);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchCountry = async () => {
    try {
      const result = await axios.get(
        "https://api.countrystatecity.in/v1/countries",
        {
          headers: {
            "X-CSCAPI-KEY":
              "eVNMbzF6NEVPNFFld1IyMXlvVlN5OVpMaExVWFN1RG82elc2SFBscg==",
          },
        }
      );

      setCountryOption(result.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchClients = async () => {
    try {
      await getAllClients(dispatch);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllPartnerAdmins = async () => {
    try {
      await fetchAllPartnerAdmins(dispatch);
    } catch (error) {
      console.log(error);
    }
  };
  const getZyvkaUsers = async () => {
    try {
      await getAllZyvkaUsers(dispatch);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchJobData = async () => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/jobs/getJobByID/${jobID}`,
        { withCredentials: true }
      );
      console.log(result?.data)
      setFormData({
        job_title: result.data?.job_title,
        zyvka_team_members: result?.data?.zyvka_team_members,
        jobType: result?.data?.jobType,
        hotJob: result?.data?.hotJob,
        company_name: result?.data?.company_name,
        clientUserId: result?.data?.clientUserId,
        min_experience_req: result?.data?.min_experience_req,
        max_experience_req: result?.data?.max_experience_req,
        skills_req: result?.data?.skills_req,
        file: null,
        prevJD: result?.data?.jd_urn,
        number_of_rounds: result?.data?.number_of_rounds,
        number_of_positions: result?.data?.number_of_positions,
        openToAll: result?.data?.openToAll,
        assigned_partners: result?.data?.assigned_partners,
        certification: result?.data?.certification,
        prefered_industry: result?.data?.prefered_industry,
        remoteJob: result?.data?.isRemote,
        esop: result?.data?.ESOP,
        drive: result?.data?.Drive,
        IsInternalTeamWorking: result?.data?.internalTeamWorking,
        isVideoResumeRequired: result?.data?.isVideoResumeRequired,
        isZyvkaAiEnable: result?.data?.isZyvkaAiEnable,
        openToInternalTeamOnly: result?.data?.openToInternalTeamOnly,
        currencyType: result?.data?.currencyType,
        round_name: "",
        round_desc: "",
        hiring_process: result?.data?.hiring_process,
        job_description: result?.data?.job_description,
        mnCtc: result?.data?.min_CTC,
        mxCtc: result?.data?.max_CTC,
        notice_period: result?.data?.notice_period,
        prev_country: result.data?.country,
        prev_state: result?.data?.state,
        prev_city: result?.data?.city,
        //addtn
        questionType: [],
        questions: [],

        country: "",
        state: "",
        city: "",
        jd_urn: result?.data?.jd_urn,
      });

      setSelectedCLientID(result?.data?.client?.id);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchJobProfiles();
    fetchClients();
    fetchCountry();
    fetchJobData();
    fetchSkills();
    getAllPartnerAdmins();
    getZyvkaUsers();
    fetchGlobalSkills();
  }, []);

  const fetchState = async () => {
    try {
      if (country) {
        const result = await axios.get(
          `https://api.countrystatecity.in/v1/countries/${country}/states`,
          {
            headers: {
              "X-CSCAPI-KEY":
                "eVNMbzF6NEVPNFFld1IyMXlvVlN5OVpMaExVWFN1RG82elc2SFBscg==",
            },
          }
        );
        setStateOption(result.data);
      }

      // console.log(result.data);
    } catch (error) { }
  };
  const fetchCity = async () => {
    try {
      if (state) {
        const result = await axios.get(
          `https://api.countrystatecity.in/v1/countries/${country}/states/${state}/cities`,
          {
            headers: {
              "X-CSCAPI-KEY":
                "eVNMbzF6NEVPNFFld1IyMXlvVlN5OVpMaExVWFN1RG82elc2SFBscg==",
            },
          }
        );
        setCityOption(result.data);
      }

      // console.log(city);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchGlobalSkills = async () => {
    try {
      await fetchAllSkills(dispatch);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchSkills = async () => {
    try {
      if (profile) {
        const result = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/zyvka/getSkillForJobProfile/${profile}`,
          { withCredentials: true }
        );

        setFormData({ ...formData, skills_req: result?.data });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchState();
  }, [country]);
  useEffect(() => {
    fetchCity();
  }, [state]);
  useEffect(() => {
    fetchSkills();
  }, [profile]);

  const AllProfiles = userState.gProfile;
  const AllSkills = userState.gSkills;
  const changeHandlerCountry = (e) => {
    setCountry(e);
    setFormData({ ...formData, state: null });
    setFormData({ ...formData, city: null });
  };
  const changeHandlerProfile = (e) => {
    // console.log(e);
    setProfile(e);
  };

  const changeHandlerState = (e) => {
    // console.log(e);
    setState(e);
    setFormData({ ...formData, city: null });
  };

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const addRoundHandler = () => {
    //todo add R1 - {name}
    // let res = `R-${}`
    // R{idx} - aptitude round
    let idx = formData?.hiring_process.length;
    let res = `Round ${idx + 1}`;

    let tmp = {
      name: res,
      description: formData?.round_desc,
    };

    tmp = JSON.stringify(tmp);
    let tmp1 = formData?.hiring_process;
    tmp1.push(tmp);

    setFormData({
      ...formData,
      hiring_process: tmp1,
    });

  };
  const deleteRoundHandler = (idx) => {
    let prev_value = formData?.hiring_process;

    const new_value = prev_value.splice(idx, 1);
    setFormData({
      ...formData,
      [formData.hiring_process]: new_value,
    });
  };

  const partnerAdmins = useSelector(selectAllPartnerAdminsDetails);
  const zyvkaMembers = zyvkaState?.zyvkaMembers;
  const getValue = (value) => {
    setFormData({ ...formData, job_description: value });
    console.log(formData);
  };

  //addtn
  const deleteQuestionHandler = (idx) => {
    let prev_ques = formData?.questions;
    let prev_ques_type = formData?.questionType;
    const new_ques = prev_ques.splice(idx, 1);
    const new_ques_type = prev_ques_type.splice(idx, 1);
    setFormData({
      ...formData,
      [formData.questions]: new_ques,
      [formData.questionType]: new_ques_type
    });
  }
  // const zyvkaMembers = zyvkaState?.zyvkaMembers.filter(function (elem) {
  //   if (elem.id !== user?.id) {
  //     return elem;
  //   }
  // });
  console.log(formData);
  return (
    <div>
      <br></br>
      {formData !== null ? (
        <Card
          headStyle={{ fontSize: "25px", fontWeight: "bold" }}
          bordered={false}
          style={{
            width: "auto",
            padding: "15px",
            maxWidth: "900px",
          }}
        >
          <Card.Content header="Open new position"></Card.Content>
          <Card.Content>
            <Form onSubmit={onFinish}>
              <Form.Select
                search
                label="Job Title"
                name="job_title"
                value={formData?.job_title}
                required={true}
                onChange={(e, data) => {
                  setFormData({ ...formData, [data.name]: data.value });
                  const val = AllProfiles.find(
                    (elem) => elem.profile === data.value
                  );
                  changeHandlerProfile(val?.id);
                }}
                options={AllProfiles.map((data, idx) => {
                  return { key: idx, text: data.profile, value: data.profile };
                })}
                placeholder="Select Job Profile"
              ></Form.Select>
              {userState?.role === "superAdmin" && (
              <Form.Select
                search
                label="Zyvka Team"
                name="zyvka_team_members"
                multiple
                placeholder="Select people with access to updating this job"
                options={zyvkaMembers.map((data, idx) => {
                  return {
                    key: idx,
                    text: `${data.firstName} ${data.lastName} (${data.email})`,
                    value: data.id,
                    image: { avatar: true, src: profilePicURL(data.pfp_urn) },
                  };
                })}
                value={formData?.zyvka_team_members}
                onChange={(e, data) => {
                  setFormData({ ...formData, [data.name]: data.value });
                }}
              ></Form.Select>
              )}
              <Form.Group widths={"equal"}>
                <Form.Select
                  label="Job Type"
                  name="jobType"
                  placeholder="Select Job Type"
                  value={formData?.jobType}
                  onChange={(e, { name, value }) => {
                    setFormData({ ...formData, [name]: value });
                  }}
                  required={true}
                  options={[
                    { key: 0, text: "Tech", value: "Tech" },
                    { key: 1, text: "Non-Tech", value: "Non-Tech" },
                  ]}
                ></Form.Select>
                <Form.Checkbox
                  toggle
                  label="Hot Job"
                  style={{ marginTop: "30px" }}
                  checked={formData?.hotJob}
                  onChange={(e, data) => {
                    setFormData({ ...formData, [data.name]: data.checked });
                  }}
                  name="hotJob"
                ></Form.Checkbox>
              </Form.Group>
              <Form.Group widths={"equal"}>
                <Form.Select
                  search
                  name="company_name"
                  label="Company"
                  value={formData?.company_name}
                  required={true}
                  onChange={(e, data) => {
                    setFormData({ ...formData, [data.name]: data.value });
                    clientSelectedHandler(data.value);
                  }}
                  options={clients.map((data, idx) => {
                    return { key: idx, value: data.name, text: data.name };
                  })}
                  placeholder="Select Company/Client"
                ></Form.Select>
                <Form.Select
                  search
                  label="Client Contact"
                  required={true}
                  name="clientUserId"
                  value={formData?.clientUserId}
                  onChange={(e, data) => {
                    setFormData({ ...formData, [data.name]: data.value });
                  }}
                  options={clients
                    .find((item) => item.id === selectedClientID)
                    ?.contact_person.map((data, idx) => {
                      return {
                        key: idx,
                        value: data.id,
                        text: `${data?.name} (${data?.email})`,
                      };
                    })}
                  disabled={
                    clients.find((item) => item.id === selectedClientID)
                      ?.contact_person.length === 0
                  }
                  placeholder="Select Contact Person"
                ></Form.Select>
              </Form.Group>
              <Form.Group widths={"equal"}>
                <Form.Input
                  type="number"
                  name="min_experience_req"
                  label="Minimum experience"
                  value={formData?.min_experience_req}
                  onChange={changeHandler}
                  placeholder="Min exp. required (in years)"
                ></Form.Input>
                <Form.Input
                  type="number"
                  name="max_experience_req"
                  label="Maximum experience"
                  value={formData?.max_experience_req}
                  onChange={changeHandler}
                  placeholder="Max. exp. required (in years)"
                ></Form.Input>
              </Form.Group>
              <Form.Input
                type="text"
                name="prefered_industry"
                label="Preferred Industry"
                value={formData?.prefered_industry}
                onChange={changeHandler}
                placeholder="Preferred Industry"
              ></Form.Input>
              <label style={{ fontWeight: "bolder" }}>Job Description</label>
              <RichTextEditor
                initialValue={formData?.job_description}
                getValue={getValue}
              />
              <br />

              <Form.Select
                search
                multiple
                options={AllSkills.map((data, idx) => {
                  return { key: idx, value: data.skill, text: data.skill };
                })}
                placeholder="Select required skills"
                name="skills_req"
                value={formData?.skills_req}
                onChange={(e, data) => {
                  setFormData({ ...formData, [data.name]: data.value });
                }}
                label="Skills"
              ></Form.Select>
              <Form.Group widths={"equal"}>
                <Form.Field
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <a
                    style={{
                      marginTop: "30px",
                      color: "#6855f6",
                      textDecoration: "underline",
                      fontWeight: "bolder",
                    }}
                    href={getJdFromURL(formData?.jd_urn)}
                    target="_blank" rel="noreferrer"
                  >
                    Previous JD
                  </a>
                  <a
                    style={{
                      marginTop: "30px",
                      color: "#e74c3c",
                      textDecoration: "underline",
                    }}
                    target="_blank"
                    onClick={() => {
                      setOpenJD((openJD) => !openJD);
                    }}
                  >
                    {`${openJD ? "use previous" : "Change"}`}
                  </a>
                </Form.Field>
                <Form.Input
                  type="file"
                  name="file"
                  label="Upload JD"
                  disabled={!openJD}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.files[0],
                    });
                  }}
                  required={true}
                  accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                ></Form.Input>
              </Form.Group>
              <Form.Input
                type="number"
                label="Number of rounds"
                name="number_of_rounds"
                value={formData?.number_of_rounds}
                onChange={changeHandler}
                placeholder="Enter Total Number of Rounds"
              ></Form.Input>
              <Card style={{ width: "auto", padding: "20px" }}>
                {" "}
                {formData?.hiring_process.length > 0 && (
                  <Item.Group divided>
                    {formData?.hiring_process.map((data, idx) => {
                      return (
                        <Item key={idx}>
                          {/* <Item.Image src="https://react.semantic-ui.com/images/wireframe/image.png" /> */}

                          <Item.Content>
                            <Item.Header
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span>Round {idx + 1}</span>
                              <Button
                                type="button"
                                floated="right"
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                  padding: "5px",
                                }}
                                onClick={() => {
                                  deleteRoundHandler(idx);
                                }}
                              >
                                <Icon name="close" style={{ margin: "0px" }} />
                              </Button>
                            </Item.Header>

                            <Item.Description>
                              <Item.Meta>
                                <span className="cinema">
                                  {JSON.parse(data).description}
                                </span>
                              </Item.Meta>
                            </Item.Description>
                            <Item.Extra></Item.Extra>
                          </Item.Content>
                        </Item>
                      );
                    })}
                  </Item.Group>
                )}
                <Divider />
                <p
                  name="round_name"
                  onChange={changeHandler}
                >
                  Round {formData?.hiring_process.length + 1}
                </p>
                <Form.TextArea
                  placeholder="Round Description"
                  name="round_desc"
                  value={formData?.round_desc}
                  onChange={changeHandler}
                ></Form.TextArea>
                <Form.Group>
                  <Form.Button
                    type="button"
                    onClick={addRoundHandler}
                    style={{ backgroundColor: "#6855f6", color: "#fff" }}
                  >
                    Add
                  </Form.Button>
                  <Form.Button type="button">Cancel</Form.Button>
                </Form.Group>
              </Card>

              <Form.Group widths={"equal"}>
                <Form.Input
                  name="number_of_positions"
                  label="Open Positions"
                  placeholder="Enter Number of Open Positions"
                  value={formData?.number_of_positions}
                  onChange={changeHandler}
                ></Form.Input>
                {userState?.role === "superAdmin" && (
                <Form.Checkbox
                  toggle
                  style={{ marginTop: "30px" }}
                  name="openToAll"
                  label="Open to all ?"
                  checked={formData?.openToAll}
                  onChange={(e, data) => {
                    setFormData({ ...formData, [data.name]: data.checked });
                  }}
                ></Form.Checkbox>
                )}
                <Form.Checkbox
                  toggle
                  style={{ marginTop: "30px" }}
                  name="openToInternalTeamOnly"
                  label="Open to internalTeam only ?"
                  //checked={formData?.openToInternalTeamOnly}
                  checked={userState?.role !== "superAdmin" ? true : formData?.openToInternalTeamOnly}
                  onChange={(e, data) => {
                    setFormData({ ...formData, [data.name]: data.checked });
                  }}
                ></Form.Checkbox>
                <Form.Checkbox
                  toggle
                  style={{ marginTop: "30px" }}
                  name="IsInternalTeamWorking"
                  label="Internal Team Working ?"
                  //checked={formData?.IsInternalTeamWorking}
                  checked={userState?.role !== "superAdmin" ? true : formData?.IsInternalTeamWorking}
                  onChange={(e, data) => {
                    setFormData({ ...formData, [data.name]: data.checked });
                  }}
                ></Form.Checkbox>
              </Form.Group>

              {!(formData?.openToAll || formData?.openToInternalTeamOnly) && userState?.role === "superAdmin" && (
                <Form.Select
                  multiple
                  search
                  name="assigned_partners"
                  label="Assign Job"
                  value={formData?.assigned_partners}
                  onChange={(e, data) => {
                    setFormData({ ...formData, [data.name]: data.value });
                  }}
                  options={partnerAdmins.map((data, idx) => {
                    return {
                      key: idx,
                      text: `${data.firstName} ${data.lastName} ( ${data.email} )`,
                      value: data.id,
                      image: { avatar: true, src: profilePicURL(data.pfp_urn) },
                    };
                  })}
                  placeholder="Select partners for this job"
                ></Form.Select>
              )}

              <Form.Input
                name="certification"
                label="Certification"
                placeholder="Cetrification (If any)"
                value={formData?.certification}
                onChange={changeHandler}
              ></Form.Input>
              <Form.Group
                style={{ display: `${openLocation === true && "none"}` }}
              >
                <Form.Input
                  label="Location"
                  value={`${formData?.prev_city ? formData?.prev_city : ""}, ${formData?.prev_state ? formData?.prev_state : ""
                    }, ${formData?.prev_country}`}
                  disabled
                ></Form.Input>
                <a
                  style={{
                    marginTop: "30px",
                    color: "#6855f6",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    setOpentLocation(true);
                  }}
                >
                  Change
                </a>
              </Form.Group>

              {/* addtn */}
              <Item.Group>
                {
                  formData?.questions.map((el, idx) => {
                    return (
                      <Item key={idx}>
                        <Item.Content>
                          <Item.Header
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>
                              {el}
                            </span>
                            <Button
                              floated="right"
                              style={{
                                backgroundColor: "red",
                                color: "white",
                                padding: "5px",
                              }}
                              onClick={() => {
                                deleteQuestionHandler(idx);
                              }}
                            >
                              <Icon name="close" style={{ margin: "0px" }} />
                            </Button>
                          </Item.Header>
                          <Item.Extra></Item.Extra>
                        </Item.Content>
                      </Item>
                    )
                  })
                }
                {
                  formData?.questionType?.length === 0 ? (
                    <p>Please provide the questions you would like the candidate to answer</p>
                  ) : null
                }
                <Form.Button type="button" onClick={addQuestionClick}>Add Questions</Form.Button>
                {showQuestionModal ? <QuestionsForJob setFormData={setFormData} formData={formData} Open={true} /> : null}
              </Item.Group>


              <Form.Group
                widths={"equal"}
                style={{
                  display: `${openLocation === false ? "none" : ""}`,
                }}
              >
                {/* U+200E is a blank character inserted for proper spacing, nothing else */}
                <Form.Select
                  search
                  fluid
                  name="country"
                  label="Location"
                  required={true}
                  placeholder="Select country"
                  value={formData?.country}
                  onChange={(e, data) => {
                    changeHandlerCountry(data.value);
                    setFormData({
                      ...formData,
                      [data.name]: data.value,
                    });
                  }}
                  options={countryOption.map((data, idx) => {
                    return {
                      text: `${data.name + "  (" + data.iso2 + ")"}`,
                      value: `${data.iso2}`,
                      key: `${idx}`,
                    };
                  })}
                />
                <Form.Select
                  search
                  fluid
                  label="‎ "
                  placeholder="Select State"
                  name="state"
                  value={formData?.state}
                  onChange={(e, data) => {
                    changeHandlerState(data.value);
                    setFormData({
                      ...formData,
                      [data.name]: data.value,
                    });
                  }}
                  options={stateOption.map((data, idx) => {
                    return {
                      text: `${data.name + "  (" + data.iso2 + ")"}`,
                      value: `${data.iso2}`,
                      key: `${idx}`,
                    };
                  })}
                />
                <Form.Select
                  fluid
                  search
                  label="‎ "
                  placeholder="Select City"
                  name="city"
                  value={formData?.city}
                  onChange={(e, data) => {
                    setFormData({ ...formData, [data.name]: data.value });
                  }}
                  options={cityOption.map((data, idx) => {
                    return {
                      text: `${data.name}`,
                      value: `${data.name}`,
                      key: `${idx}`,
                    };
                  })}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Checkbox
                  toggle
                  name="remoteJob"
                  label="Remote Job ?"
                  checked={formData?.remoteJob}
                  onChange={(e, data) => {
                    setFormData({ ...formData, [data.name]: data.checked });
                  }}
                ></Form.Checkbox>
                <Form.Checkbox
                  toggle
                  name="esop"
                  label="ESOP ?"
                  checked={formData?.esop}
                  onChange={(e, data) => {
                    setFormData({ ...formData, [data.name]: data.checked });
                  }}
                ></Form.Checkbox>
                <Form.Checkbox
                  toggle
                  name="drive"
                  label="Bulk ?"
                  checked={formData?.drive}
                  onChange={(e, data) => {
                    setFormData({ ...formData, [data.name]: data.checked });
                  }}
                ></Form.Checkbox>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Select
                  options={Currency}
                  label="Currency Type"
                  required={true}
                  name="currencyType"
                  value={formData?.currencyType}
                  onChange={(e, data) => {
                    setFormData({ ...formData, [data.name]: data.value });
                  }}
                ></Form.Select>
                <Form.Input
                  type="number"
                  label={`Minimum CTC ( In ${formData?.currencyType === "INR" ? "LPA" : "KPA"
                    })`}
                  required={true}
                  name="mnCtc"
                  placeholder={`Enter Minimum ctc (in ${formData?.currencyType === "INR" ? "LPA" : "KPA"
                    })`}
                  value={formData?.mnCtc}
                  onChange={(e, data) => {
                    setFormData({ ...formData, [data.name]: data.value });
                  }}
                ></Form.Input>
                <Form.Input
                  type="number"
                  label={`Maximum CTC ( In ${formData?.currencyType === "INR" ? "LPA" : "KPA"
                    })`}
                  required={true}
                  placeholder={`Enter maximum ctc (in ${formData?.currencyType === "INR" ? "LPA" : "KPA"
                    })`}
                  name="mxCtc"
                  value={formData?.mxCtc}
                  onChange={(e, data) => {
                    setFormData({ ...formData, [data.name]: data.value });
                  }}
                ></Form.Input>
              </Form.Group>

            <Form.Group widths="equal">
              <Form.Input
                type="number"
                name="notice_period"
                label="Notice Period"
                placeholder="Enter notice period (in days)"
                value={formData?.notice_period}
                onChange={changeHandler}
              ></Form.Input>
                <Form.Checkbox
                  toggle
                  style={{ marginTop: "30px" }}
                  name="isVideoResumeRequired"
                  label="Video Resume Required ?"
                  checked={formData?.isVideoResumeRequired}
                  onChange={(e, data) => {
                    setFormData({ ...formData, [data.name]: data.checked });
                  }}
                ></Form.Checkbox>
                <Form.Checkbox
                  toggle
                  style={{ marginTop: "30px" }}
                  name="isZyvkaAiEnable"
                  label="Enable ZyvkaAI ?"
                  checked={formData?.isZyvkaAiEnable}
                  onChange={(e, data) => {
                    setFormData({ ...formData, [data.name]: data.checked });
                  }}
                ></Form.Checkbox>
              </Form.Group>
              {/* <ErrorMessage header="Error" content={"Some Error Occured"} /> */}
              <Form.Button
                type="submit"
                style={{ backgroundColor: "#6855f6", color: "#fff" }}
              >
                Submit
              </Form.Button>
            </Form>
          </Card.Content>
        </Card>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          Loading...
        </div>
      )}
    </div>
  );
};

export default CloneJob;
